import React, { useRef } from 'react';
import { Container, Nav, Row, Col, Button, Input, FormGroup } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

import { Heading1, Heading2, Paragraph } from '../../components/text';
import Layout from '../../components/Layout';
import SocialMedia from '../../components/SocialMedia';


const validationSchema = yup.object({
  'Name': yup.string().label('Name').min(6).required(),
  'Email': yup.string().label('Email').email().required(),
  'Phone': yup.string().label('Phone').matches(/[0-9]{7}/, 'Please enter your 7 digit phone number with no spaces or dashes').required(),
  'Comment': yup.string().label('Comment').required(),
});

const initialValues = {
  'Name': '',
  'Email': '',
  'Phone': '',
  'Comment': '',
};


function Contact() {
  const meta = [
    {
      name: 'description',
      content: 'Tell us what you think! We want to hear from you'
    },
    {
      name: 'keywords',
      content: 'Subway, Trinidad, Contact Subway, Tobago, Trinidad Subway Phone number'
    }
  ];

  const formElement = useRef(null);
  const handleSubmit = (values) => {
    formElement.current.submit();
  };

  return (
    <Layout title="Contact" meta={meta} className="py-3 py-md-5">
      <Container fluid className="px-md-6">
        <Row>
          <Col sm={12} md={4}>
            <Heading1 sm={2} md={4} className="text-secondary m-0">
              Contact Us
            </Heading1>
            
            <Heading2 sm={1} md={1.2} className="m-0">Phone</Heading2>
            <ul className="list-unstyled">
              <li>
                <Paragraph sm={1} md={1.2} className="m-0">
                  800-4SUB (Hotline)
                </Paragraph>
              </li>
              <li>
                <Paragraph sm={1} md={1.2}>
                  226-SUBS (Restaurant Support Center)
                </Paragraph>
              </li>
            </ul>
            
            <Heading2 sm={1} md={1.2} className="m-0">Email</Heading2>
            <Paragraph sm={1} md={1.2}>subway.marketing@phl-tt.com</Paragraph>

            <Heading2 sm={1} md={1.2} className="m-0">Website</Heading2>
            <Paragraph sm={1} md={1.2}>www.subway-tt.com</Paragraph>

            <Heading2 sm={1} md={1.2} className="text-secondary m-0">
              Follow Us on Social Media
            </Heading2>
            <Nav>
              <SocialMedia />
            </Nav>
          </Col>
          
          <Col sm={12} md={6} className="my-4 my-md-0">
            <Heading2 sm={1} md={1.2}>Leave Us a Comment!</Heading2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
            <Form
              ref={formElement}
              method="POST"
              name="contact"
              action="/contact/success/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <label className="d-none">Don’t fill this out if you're human: <input name="bot-field" /></label>
              <input type="hidden" name="form-name" value="contact" />
              <FormGroup>
                <Field
                  as={Input}
                  name="Name"
                  placeholder="Name"
                  autoComplete="name"
                />
                <ErrorMessage
                  name="Name"
                  component="div"
                  className="invalid-feedback d-block text-white"
                />
              </FormGroup>
              <FormGroup>
                <Field
                  as={Input}
                  name="Phone"
                  placeholder="Enter phone contact"
                  autoComplete="tel-local"
                />
                <ErrorMessage
                  name="Phone"
                  component="div"
                  className="invalid-feedback d-block text-white"
                />
              </FormGroup>
              <FormGroup>
                <Field
                  as={Input}
                  type="email"
                  name="Email"
                  placeholder="Enter email"
                />
                <ErrorMessage
                  name="Email"
                  component="div"
                  className="invalid-feedback d-block text-white"
                />
                </FormGroup>
              <FormGroup>
                <Field
                  as={Input}
                  type="textarea"
                  name="Comment"
                  placeholder="Leave your comment here..."
                />
                <ErrorMessage
                  name="Comment"
                  component="div"
                  className="invalid-feedback d-block text-white"
                />
              </FormGroup>
              <Button type="submit" value="Send" color="white">Submit</Button>
            </Form>
            </Formik>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Contact;
